import { isWholeNumber } from "../../../../helpers/clientMaths";

export function paymentSettingsValidation(
  prefix,
  minPaymentsVar,
  maxPaymentsVar,
  paymentDenominationsVar
) {
  let errors = {};

  if (!parseInt(minPaymentsVar)) {
    errors[`min${prefix}Payments`] = "Required";
  }

  if (parseInt(minPaymentsVar) > 999) {
    errors[`min${prefix}Payments`] = "Max 999";
  }

  if (parseInt(minPaymentsVar) < 1) {
    errors[`min${prefix}Payments`] = "Minimum 1";
  }

  if (!parseInt(maxPaymentsVar)) {
    errors[`max${prefix}Payments`] = "Required";
  }

  if (parseInt(maxPaymentsVar) > 999) {
    errors[`max${prefix}Payments`] = "Max 999";
  }

  if (parseInt(maxPaymentsVar) < 1) {
    errors[`max${prefix}Payments`] = "Minimum 1";
  }

  if (parseInt(maxPaymentsVar) <= parseInt(minPaymentsVar)) {
    errors[`max${prefix}Payments`] =
      "Maximum payments cannot be less than the minimum payments";
  }

  if (parseInt(minPaymentsVar) >= parseInt(maxPaymentsVar)) {
    errors[`min${prefix}Payments`] =
      "Minimum payments cannot be more than the maximum payments";
  }

  if (
    !paymentDenominationsVar ||
    (paymentDenominationsVar && !paymentDenominationsVar.length)
  ) {
    errors[`${prefix.toLowerCase()}PaymentDenominations`] = "Required";
  }

  return errors;
}

export function paymentDenominataionValidation(
  amount,
  paymentDenominationsVar
) {
  let errors = {};

  if (!parseInt(amount)) {
    errors.amount = "Required";
  }

  if (parseInt(amount) > 999) {
    errors.amount = "Amount cannot be more than 999";
  }

  if (paymentDenominationsVar.includes(parseInt(amount))) {
    errors.amount = "Amount already added";
  }

  if (!isWholeNumber(amount)) {
    errors.amount = "Must be a whole number";
  }

  return errors;
}
